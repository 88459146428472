import React from 'react'
import styled from 'styled-components'

const SmallTitleWrapper = styled.span`
  font-style:italic;
  text-transform:uppercase;
  color:${props => props.color === 'darkest' ? props.theme.colors.darkest : props.theme.colors.primary };
  display:block;
  margin-bottom:.75em;
  @media (min-width: 562px) {
    margin-bottom:1.5em;
  }
`

const SmallTitle = (props) => {
  const{
    color
  } = props
  return(
    <SmallTitleWrapper color={color}>
      {props.children}
    </SmallTitleWrapper>
  )
}

export default SmallTitle