import React from "react"

import Layout from "../components/layout"
import Carrousel from '../components/Carrousel'

const IndexPage = ({props}) => {
  return(
    <Layout>
      <Carrousel />
    </Layout>
  )
}

export default IndexPage
