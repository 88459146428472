import React from "react"
import styled from "styled-components"
import SmallTitle from "../SmallTitle"
const ListWrapper = styled.section`
  padding: 5rem 1.5em 0;
  @media (min-width: 562px) {
    padding: 3em;
    width: ${(883 / 1344) * 100}%;
    margin-left: calc(${(421 / 1343) * 100}% + 3em);
  }
  ul {
    @media (min-width: 562px) {
      font-size: ${props => props.theme.fontSizes.tablet.l};
    }
    li {
      margin-bottom: 0.5em;
    }
    &.long {
      @media (min-width: 1024px) {
        li {
          margin-bottom: 0.5em;
        }
        column-count: 2;
        column-gap: 1em;
      }
    }
    &:not(.long) {
      @media (min-width: 1024px) {
        font-size: ${props => props.theme.fontSizes.desktop.l};
        li {
          margin-bottom: 0.25em;
        }
      }
    }
  }
  li {
    font-style: italic;
    text-transform: uppercase;
  }
  + * {
    margin-top: 3em;
    @media (min-width: 562px) {
      margin-top: 4rem;
    }
    @media (min-width: 1024px) {
      margin-top: 7rem;
    }
  }
`

const List = props => {
  return (
    <ListWrapper className="animatable">
      <h3>
        <SmallTitle>Clients</SmallTitle>
      </h3>
      <ul className="long">
        <li className="animatable">
          <p>Dom Pérignon</p>
        </li>
        <li className="animatable">
          <p>Nyx professional makeup</p>
        </li>
        <li className="animatable">
          <p>Krug</p>
        </li>
        <li className="animatable">
          <p>GN Group</p>
        </li>
        <li className="animatable">
          <p>DS Automobiles</p>
        </li>
        <li className="animatable">
          <p>Schweppes</p>
        </li>
        <li className="animatable">
          <p>Veuve Clicquot</p>
        </li>
        <li className="animatable">
          <p>L'Oréal Paris</p>
        </li>
        <li className="animatable">
          <p>Playstation</p>
        </li>
        <li className="animatable">
          <p>Garnier</p>
        </li>
        <li className="animatable">
          <p>Moët Hennessy</p>
        </li>
        <li className="animatable">
          <p>Danmarks Nationalbank</p>
        </li>
        <li className="animatable">
          <p>Paramount Pictures</p>
        </li>
        <li className="animatable">
          <p>Nationalmuseet</p>
        </li>
        <li className="animatable">
          <p>Maybelline</p>
        </li>
        <li className="animatable">
          <p>Volkswagen</p>
        </li>
        <li className="animatable">
          <p>La Roche Posay</p>
        </li>
        <li className="animatable">
          <p>HBO MAX</p>
        </li>
        <li className="animatable">
          <p>Carlsberg</p>
        </li>
        <li className="animatable">
          <p>LEXUS</p>
        </li>
        <li className="animatable">
          <p>Marvelous </p>
        </li>
      </ul>
    </ListWrapper>
  )
}

export default List
