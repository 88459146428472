import React, { useEffect, useRef } from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import gsap from "gsap"

import get from "lodash/get"
import TwoLinesTitle from "../TwoLinesTitle"

const StatementsWrapper = styled.div`
  position: absolute;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  .two-lines-title {
    bottom: unset !important;
    left: unset !important;
    width: auto;

    &:last-child {
      div {
        &:last-child {
          color: ${props => props.theme.colors.primary};
        }
      }
    }
  }
`

const Statements = props => {
  const data = useStaticQuery(graphql`
    query StatementsQuery {
      prismic {
        allLayouts {
          edges {
            node {
              statements {
                statement_line_1
                statement_line_2
              }
            }
          }
        }
      }
    }
  `)

  const { play, loop } = props

  const statementsData = get(
    data,
    "prismic.allLayouts.edges[0].node.statements"
  )
  const statementWrapperRef = useRef()
  const tl = useRef()

  useEffect(() => {
    const els = statementWrapperRef.current.querySelectorAll(".two-lines-title")
    tl.current = gsap.timeline({
      paused: true,
      repeat: loop ? -1 : false,
    })
    els.forEach((el, i) => {
      tl.current.from(el.querySelectorAll("div"), {
        autoAlpha: 0,
        duration: 1,
        stagger: 0.25,
        yPercent: 12.5,
        delay: i === 0 && !loop && 0.5,
      })
      if (i !== els.length - 1 || loop) {
        tl.current.to(el.querySelectorAll("div"), {
          autoAlpha: 0,
          delay: loop ? 1 : false,
          duration: 0.5,
          yPercent: -12.5,
        })
      }
    })
  }, [])

  useEffect(() => {
    play === true ? tl.current.progress(0).play() : tl.current.pause()
  }, [play])

  return (
    <StatementsWrapper ref={statementWrapperRef}>
      {statementsData.map((statement, i) => {
        return (
          <TwoLinesTitle
            key={i}
            titleLineOne={get(statement, "statement_line_1[0].text")}
            titleLineTwo={get(statement, "statement_line_2[0].text")}
          />
        )
      })}
    </StatementsWrapper>
  )
}

export default Statements
