import React, { useEffect, useState, useRef, useContext } from "react"
import styled from "styled-components"
import TransitionLink from "gatsby-plugin-transition-link"
import get from "lodash/get"
import { useStaticQuery, graphql } from "gatsby"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { ScrollToPlugin } from "gsap/ScrollToPlugin"
import { linkResolver } from "../../utils/linkResolver"
import TwoLinesTitle from "../TwoLinesTitle"
import Paragraph from "../Paragraph"
import LazyImage from "../LazyImage"
import CTA from "../CTA"
import Statements from "../Statements"
import SEO from "../seo"
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../../context/GlobalContextProvider"
import Clients from "./Clients"

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin)

// helper function that lets us define a section in a timeline that spans between two times (start/end) and lets us add onEnter/onLeave/onEnterBack/onLeaveBack callbacks
function addSectionCallbacks(
  timeline,
  { start, end, param, onEnter, onLeave, onEnterBack, onLeaveBack }
) {
  let trackDirection = animation => {
      // just adds a "direction" property to the animation that tracks the moment-by-moment playback direction (1 = forward, -1 = backward)
      let onUpdate = animation.eventCallback("onUpdate"), // in case it already has an onUpdate
        prevTime = animation.time()
      animation.direction = animation.reversed() ? -1 : 1
      animation.eventCallback("onUpdate", () => {
        let time = animation.time()
        if (prevTime !== time) {
          animation.direction = time < prevTime ? -1 : 1
          prevTime = time
        }
        onUpdate && onUpdate.call(animation)
      })
    },
    empty = v => v // in case one of the callbacks isn't defined
  timeline.direction || trackDirection(timeline) // make sure direction tracking is enabled on the timeline
  start >= 0 &&
    timeline.add(
      () => ((timeline.direction < 0 ? onLeaveBack : onEnter) || empty)(param),
      start
    )
  end <= timeline.duration() &&
    timeline.add(
      () => ((timeline.direction < 0 ? onEnterBack : onLeave) || empty)(param),
      end
    )
}

const CarrouselWrapper = styled.section`
  width: 900%;
  display: flex;
  flex-wrap: nowrap;
  opacity: 0;
`

const Slide = styled.article`
  width: 100%;
  height: 100%;
  position: relative;

  .two-lines-title {
    position: absolute;
    bottom: 8em;
    left: 1.5em;
    @media (min-width: 562px) {
      bottom: 3em;
      left: 3em;
    }
  }
`

const DescriptionWrapper = styled.div`
  position: absolute;
  right: 0;
  bottom: 60px;
  padding: 5em 1.5em 0;

  @media (min-width: 562px) {
    padding: 5.5em 3em 0;
  }
  @media (min-width: 1024px) {
    padding: 0;
  }
  > * {
    &:first-child {
      margin-bottom: 1rem;
      font-size: 1.3em;
    }
  }
`

const Carrousel = props => {
  const data = useStaticQuery(graphql`
    query CarrouselQuery {
      prismic {
        allAbouts {
          edges {
            node {
              description
              teaser
              _meta {
                uid
                type
              }
            }
          }
        }
        allLayouts {
          edges {
            node {
              work {
                case {
                  ... on PRISMIC_Case {
                    title_line_1
                    title_line_2
                    description
                    _meta {
                      type
                      uid
                    }
                    thumbnail_file
                  }
                }
              }
              statements {
                statement_line_1
                statement_line_2
              }
            }
          }
        }
      }
    }
  `)

  const layoutData = get(data, "prismic.allLayouts.edges[0].node"),
    aboutData = get(data, "prismic.allAbouts.edges[0].node"),
    cases = get(layoutData, "work"),
    aboutDescription = get(aboutData, "description"),
    aboutDescriptionTeaser = get(aboutData, "teaser"),
    [slidesLength, setSlidesLength] = useState(cases.slice(0, 6).length + 2),
    [currentSlideIndex, setCurrentSlideIndex] = useState(0),
    carrouselWrapperRef = useRef(null),
    tl = useRef(null),
    dispatch = useContext(GlobalDispatchContext),
    state = useContext(GlobalStateContext)

  const handleScroll = () => {
    gsap.to(window, {
      duration: 1,
      scrollTo: (carrouselWrapperRef.current.offsetWidth * 0.75) / 7,
      ease: "power2.inOut",
    })
  }

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.scrollTo(0, 0)
    }

    const slides = gsap.utils.toArray(
      carrouselWrapperRef.current.querySelectorAll(".slide")
    )
    setSlidesLength(slides.length - 1)
    const duration = 1
    const sectionIncrement = duration / slidesLength

    tl.current = gsap.timeline({
      paused: true,
      scrollTrigger: {
        id: "carrouselTrigger",
        trigger: carrouselWrapperRef.current,
        scrub: 1,
        indicators: true,
        pin: true,
        end: `+=${carrouselWrapperRef.current.offsetWidth * 0.75}`,
        onToggle: () => {
          gsap.to(carrouselWrapperRef.current, {
            opacity: 1,
            duration: duration,
            ease: "power2.out",
          })
        },
        snap: {
          snapTo: 1 / slidesLength, // snap to the closest label in the timeline
          duration: { min: 0.125, max: 0.25 }, // the snap animation should be at least 0.2 seconds, but no more than 3 seconds (determined by velocity)
          delay: 0, // wait 0.2 seconds from the last scroll event before doing the snapping
          ease: "power2.inOut", // the ease of the snap animation ("power3" by default)
        },
      },
    })
    tl.current.to(
      slides,
      {
        xPercent: -100 * slidesLength,
        ease: "none",
        duration: duration,
      },
      "inview"
    )
    tl.current.fromTo(
      slides[1].querySelectorAll("img"),
      {
        xPercent: (125 / 1440) * -100,
      },
      {
        xPercent: 0,
        duration: duration / slidesLength,
        ease: "none",
      },
      "inview"
    )

    slides.forEach((slide, i) => {
      let tween = gsap.timeline({
        delay: 0.5,
        paused: true,
      })
      if (i !== 0 && i !== 1 && i !== slidesLength) {
        tween.fromTo(
          slide.querySelectorAll(".two-lines-title > *"),
          {
            autoAlpha: 0,
            yPercent: 12.5,
          },
          {
            autoAlpha: 1,
            duration: 1,
            stagger: 0.25,
            yPercent: 0,
            ease: "power2.inOut",
          },
          "intro"
        )
      }

      addSectionCallbacks(tl.current, {
        start: sectionIncrement * (i - 0.99),
        end: sectionIncrement * (i + 0.99),
        onEnter: () => {
          tween.play()
          setCurrentSlideIndex(i)
        },
        onLeave: () => {
          tween.reverse()
        },
        onEnterBack: () => {
          tween.play()
          setCurrentSlideIndex(i)
        },
        onLeaveBack: () => {
          if (i === 1) {
            setCurrentSlideIndex(0)
          }
          tween.reverse()
        },
      })
    })

    // unmount
    return () => {
      if (ScrollTrigger.getById("carrouselTrigger")) {
        ScrollTrigger.getById("carrouselTrigger").kill()
      }
    }
  }, [])

  return (
    <CarrouselWrapper
      ref={carrouselWrapperRef}
      className="carrousel-wrapper fullHeight"
      slidesLength={0}
    >
      <Slide
        key={0}
        className="slide"
        onClick={() => {
          handleScroll()
        }}
        onMouseMove={() => {
          dispatch({
            type: "SET_CURSOR",
            cursor: {
              visible: false,
            },
          })
        }}
      >
        <Statements play={currentSlideIndex === 0} />
        <DescriptionWrapper className="description-wrapper">
          <Paragraph className="paragraph" punchline>
            We activate brands live
          </Paragraph>
          <CTA>View Cases</CTA>
        </DescriptionWrapper>
      </Slide>
      {cases &&
        cases.slice(0, 6).map((el, i) => {
          const title_line_1 = get(el, "case.title_line_1[0].text"),
            title_line_2 = get(el, "case.title_line_2[0].text"),
            thumbnail_file = get(el, "case.thumbnail_file"),
            description = get(el, "case.description"),
            meta = get(el, "case._meta"),
            url = meta && linkResolver(meta)

          return (
            <Slide
              key={i + 1}
              className="slide"
              onMouseMove={() => {
                dispatch({
                  type: "SET_CURSOR",
                  cursor: {
                    visible: true,
                    text: "View Case",
                  },
                })
              }}
            >
              <TransitionLink
                preventScrollJump
                to={url}
                exit={{
                  trigger: ({ node, e, exit, entry }) => {
                    const allSlides = gsap.utils.toArray(
                      carrouselWrapperRef.current.querySelectorAll(".slide")
                    )
                    const slide = e.target.closest(".slide")
                    const currentIndex = allSlides.indexOf(slide)
                    const scrollOffset =
                      ((carrouselWrapperRef.current.offsetWidth * 0.75) /
                        (allSlides.length - 1)) *
                      currentIndex
                    const tl = gsap
                      .timeline()
                      .to(
                        window,
                        {
                          scrollTo: scrollOffset,
                          overwrite: "all",
                          ease: "power2.inOut",
                          duration: 0.5,
                          overwrite: "all",
                        },
                        "same"
                      )
                      .to(
                        slide.querySelectorAll(".description-wrapper"),
                        {
                          autoAlpha: 0,
                          duration: 1,
                          overwrite: "all",
                          ease: "power2.inOut",
                        },
                        "same"
                      )
                      .to(
                        slide.querySelectorAll(".two-lines-title"),
                        {
                          yPercent: 76,
                          bottom: 0,
                          duration: 1,
                          overwrite: "all",
                          ease: "power2.inOut",
                        },
                        "same"
                      )
                      .to(
                        document.querySelectorAll(".watermark"),
                        {
                          autoAlpha: 0,
                          duration: 1,
                          overwrite: "all",
                          ease: "power2.inOut",
                        },
                        "same"
                      )
                      .to(
                        slide.querySelectorAll("img"),
                        {
                          autoAlpha: 1,
                          duration: 1,
                          overwrite: "all",
                          ease: "power2.inOut",
                        },
                        "same"
                      )
                      .to(
                        slide,
                        {
                          height: "50vh",
                          duration: 1,
                          overwrite: "all",
                          ease: "power2.inOut",
                        },
                        "same"
                      )
                  },
                  length: 1,
                }}
                entry={{
                  delay: 1,
                  trigger: ({ node, e, exit, entry }) => {
                    gsap.set(window, {
                      scrollTo: 0,
                      overwrite: "all",
                    })
                  },
                }}
              >
                <LazyImage {...thumbnail_file} />
                <TwoLinesTitle
                  titleLineOne={title_line_1}
                  titleLineTwo={title_line_2}
                />
                {/* <DescriptionWrapper className="description-wrapper">
              <Paragraph data={description ? description : aboutDescription} className="paragraph" />
              <CTA theme="light">Read More</CTA>
            </DescriptionWrapper> */}
              </TransitionLink>
            </Slide>
          )
        })}
      <Slide
        key={8}
        className="slide"
        onClick={() => {
          handleScroll()
        }}
        onMouseMove={() => {
          dispatch({
            type: "SET_CURSOR",
            cursor: {
              visible: false,
            },
          })
        }}
      >
        <Clients />
      </Slide>

      <Slide
        key={9}
        className="slide about-slide fullHeight"
        onMouseMove={() => {
          dispatch({
            type: "SET_CURSOR",
            cursor: {
              visible: false,
              text: "",
            },
          })
        }}
      >
        <Statements play={currentSlideIndex === cases.slice(0, 6).length + 1} />
        <DescriptionWrapper className="description-wrapper">
          <Paragraph data={aboutDescriptionTeaser} className="paragraph" />
          <SEO
            title="Hero"
            description={get(aboutDescriptionTeaser, "[0].text")}
          />
          <TransitionLink
            to="/about"
            entry={{
              trigger: ({ node, e, exit, entry }) => {
                window.scrollTo(0, 0)
              },
            }}
          >
            <CTA>Read More</CTA>
          </TransitionLink>
        </DescriptionWrapper>
      </Slide>
    </CarrouselWrapper>
  )
}

export default Carrousel
